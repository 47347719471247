<template>
	<b-sidebar
		id="add-new-local-bank-account-sidebar"
		:visible="isAddNewLocalBankAccountSidebarActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
		shadow
		backdrop
		no-header
		right
		@hidden="resetForm"
		@change="(val) => $emit('update:is-add-new-local-bank-account-sidebar-active', val)"
	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					{{ $t('Add new local bank account') }}
				</h5>

				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click="hide"
				/>

			</div>

			<!-- BODY -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<!-- Form -->
				<b-form
					class="p-2"
					@submit.prevent="handleSubmit(onSubmit)"
					@reset.prevent="resetForm"
				>
					<!-- Bank Account Name -->
					<validation-provider
						#default="validationContext"
						name="bankAccountName"
						rules="required|alpha_spaces|min:3|max:30"
					>
						<b-form-group
							:label="$t('Bank account name')"
							label-for="bank-account-name"
						>
							<b-form-input
								v-model="localBankData.bankAccountName"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:state="getValidationState(validationContext)"
								input-id="bank-account-name"
							/>
							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Bank Account -->
					<validation-provider
						#default="validationContext"
						name="Bank Account"
						rules="required|numeric|min:6|max:20"
					>
						<b-form-group
							:label="$t('Bank account')"
							label-for="bank-account"
						>
							<b-form-input
								v-model="localBankData.bankAccount"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:state="getValidationState(validationContext)"
								input-id="bank-account"
							/>
							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Bank -->
					<validation-provider
						#default="validationContext"
						name="bank"
						rules="required"
					>
						<b-form-group
							:label="$t('Bank')"
							label-for="bank"
							:state="getValidationState(validationContext)"
						>
							<v-select
								v-model="localBankData.bankId"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:clearable="false"
								:options="bankOptions"
								:reduce="val => val.id"
								label="bank_name"
								input-id="bank"
							/>
							<b-form-invalid-feedback :state="getValidationState(validationContext)">
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Province Lists -->
					<validation-provider
						#default="validationContext"
						name="provinceLists"
						rules="required"
					>
						<b-form-group
							:label="$t('Province lists')"
							label-for="provincelist"
							:state="getValidationState(validationContext)"
						>
							<v-select
								v-model="localBankData.provinceId"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:clearable="false"
								:options="provinceLists"
								:reduce="val => val.id"
								label="name"
								input-id="provincelist"
							/>
							<b-form-invalid-feedback :state="getValidationState(validationContext)">
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Branch -->
					<validation-provider
						#default="validationContext"
						name="Branch"
					>
						<b-form-group
							:label="$t('Branch')"
							label-for="branch"
							hidden
						>
							<b-form-input
								id="branch"
								v-model="localBankData.branch"
								:state="getValidationState(validationContext)"
								trim
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Content -->
					<validation-provider
						#default="validationContext"
						name="content"
					>
						<b-form-group
							:label="$t('Content')"
							label-for="content"
						>
							<b-form-input
								id="content"
								v-model="localBankData.content"
								:state="getValidationState(validationContext)"
								trim
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Example Content -->
					<validation-provider
						#default="validationContext"
						name="example_content"
					>
						<b-form-group
							:label="$t('Example content')"
							label-for="example_content"
						>
							<b-form-input
								id="example_content"
								v-model="localBankData.example_content"
								:state="getValidationState(validationContext)"
								trim
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Transfer Type -->
					<!-- <validation-provider
						#default="validationContext"
						name="TransferType"
						rules="required"
					>
						<b-form-group
							label="Local Transfer Type"
							label-for="transferType"
							:state="getValidationState(validationContext)"
						>
							<v-select
								v-model="localBankData.localTransferType"
								multiple
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:clearable="false"
								:options="localTransferTypeLists"
								:reduce="val => val.id"
								label="name"
								input-id="transferType"
							/>

							<b-form-invalid-feedback :state="getValidationState(validationContext)">
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider> -->

					<!-- Transfer Type -->
<!--					<validation-provider-->
<!--						#default="validationContext"-->
<!--						name="Level"-->
<!--						rules="required"-->
<!--					>-->
<!--						<b-form-group-->
<!--							:label="$t('Level')"-->
<!--							label-for="level"-->
<!--							:state="getValidationState(validationContext)"-->
<!--						>-->
<!--							<v-select-->
<!--								v-model="localBankData.levelId"-->
<!--								multiple-->
<!--								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--								:clearable="false"-->
<!--								:options="levelLists"-->
<!--								:reduce="val => val.id"-->
<!--								label="name"-->
<!--								input-id="level"-->
<!--							/>-->

<!--							<b-form-invalid-feedback :state="getValidationState(validationContext)">-->
<!--								{{ validationContext.errors[0] }}-->
<!--							</b-form-invalid-feedback>-->
<!--						</b-form-group>-->
<!--					</validation-provider>-->

					<!-- Form Actions -->
					<div class="d-flex mt-2">
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="primary"
							class="mr-2"
							type="submit"
						>
							{{ $t('Add') }}
						</b-button>
						<b-button
							v-ripple.400="'rgba(186, 191, 199, 0.15)'"
							type="button"
							variant="outline-secondary"
							@click="hide"
						>
							{{ $t('Cancel') }}
						</b-button>
					</div>

				</b-form>
			</validation-observer>
		</template>
	</b-sidebar>
</template>

<script>
import {
	BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
	components: {
		BSidebar,
		BForm,
		BFormGroup,
		BFormInput,
		BFormInvalidFeedback,
		BButton,
		vSelect,

		// Form Validation
		ValidationProvider,
		ValidationObserver,
	},
	directives: {
		Ripple,
	},
	model: {
		prop: 'isAddNewLocalBankAccountSidebarActive',
		event: 'update:is-add-new-local-bank-account-sidebar-active',
	},
	props: {
		isAddNewLocalBankAccountSidebarActive: {
			type: Boolean,
			required: true,
		},
		typeOptions: {
			type: Array,
			required: true,
		},
		bankOptions: {
			type: Array,
			required: true,
		},
		provinceLists: {
			type: Array,
			required: true,
		},
		levelLists: {
			type: [Array, null],
			required: true,
		},
		localTransferTypeLists: {
			type: [Array, null],
			required: true,
		},
	},
	data() {
		return {
			required,
			alphaNum,
			email,
		}
	},
	setup(props, { emit }) {
		const toast = useToast()
		const options = ref([])
		const blankLocalBankData = {
			bankId: '',
			provinceId: '',
			bankAccountName: '',
			bankAccount: '',
			branch: '',
			content: '',
			example_content: '',
			localTransferType: [],
			// levelId: [],
		}

		const localBankData = ref(JSON.parse(JSON.stringify(blankLocalBankData)))
		const resetlocalBankData = () => {
			localBankData.value = JSON.parse(JSON.stringify(blankLocalBankData))
		}

		const onSubmit = () => {
			store.dispatch('payment-local-bank-account/addLocalBankAccount', localBankData.value)
				.then(response => {
					if (response.status == 200) {
						if (response.data.code == '00') {
							emit('refetch-data')
							emit('update:is-add-new-local-bank-account-sidebar-active', false)
							toast({
								component: ToastificationContent,
								props: {
									title: 'Success',
									icon: 'CheckIcon',
									variant: 'success',
								},
							})
						} else {
							toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						}
					}
				})
				.catch((error) => {
					toast({
						component: ToastificationContent,
						props: {
							title: 'Error add User',
							icon: 'AlertTriangleIcon',
							variant: 'danger',
						},
					})
				})
		}

		const {
			refFormObserver,
			getValidationState,
			resetForm,
		} = formValidation(resetlocalBankData)

		return {
			localBankData,
			onSubmit,
			options,

			refFormObserver,
			getValidationState,
			resetForm,
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
	.vs__dropdown-menu {
		max-height: 200px !important;
	}
}
</style>
